body {
    background-color: #000;
    color: #fff;
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
  }
  
  .admin-container {
    min-height: 100vh;
  }
  
  .admin-login,
  .admin-panel {
    width: 100%;
    max-width: 1200px;
    box-sizing: border-box;
    margin: 0 auto;
  }
  
  .admin-login h1,
  .admin-panel h1 {
    text-align: center;
    margin: 20px 0px;
    font-size: 2em;
  }
  
  .admin-login form {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .admin-login input,
  .admin-login button {
    width: 100%;
    max-width: 300px;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #333;
    background-color: #000;
    color: #fff;
    box-sizing: border-box;
  }
  
  .admin-login button,
  .logout-button {
    background-color: #333;
    cursor: pointer;
    border: none;
    color: #fff;
    padding: 10px 20px;
  }
  
  .admin-login button:hover,
  .logout-button:hover {
    background-color: #444;
  }
  
  .error-message {
    color: #ff6b6b;
    margin-top: 10px;
    text-align: center;
    font-weight: bold;
  }
  
  .admin-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .order-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    gap: 20px;
    margin-bottom: 40px;
  }
  
  .order-grid p {
    margin: 0;
  }
  
  .order-card {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    border: 2px solid;
  }
  
  .order-header {
    padding: 15px;
    text-align: center;
  }
  
  .order-card h3 {
    margin: 0;
    color: #000;
    font-weight: bold;
  }
  
  .order-info {
    background-color: #000;
    padding: 15px;
    color: #fff;
    flex-grow: 1;
  }
  
  .order-card p {
    margin: 5px 0;
  }
  
  .status-buttons {
    display: flex;
    justify-content: flex-start;
    margin-top: 15px;
  }
  
  .status-button {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: none;
    cursor: pointer;
    font-weight: bold;
    transition: all 0.3s ease;
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
  }
  
  .status-button:last-child {
    margin-right: 0;
  }
  
  .status-button::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 0;
    transition: opacity 0.3s ease;
  }
  
  .status-button:not(.active)::before {
    opacity: 1;
  }
  
  .status-button svg {
    font-size: 20px;
  }
  
  .status-pending {
    border-color: #fff9c4;
  }
  
  .status-completed {
    border-color: #c8e6c9;
  }
  
  .status-cancelled {
    border-color: #ffcdd2;
  }
  
  .status-pending .order-header {
    background-color: #fff9c4;
  }
  
  .status-completed .order-header {
    background-color: #c8e6c9;
  }
  
  .status-cancelled .order-header {
    background-color: #ffcdd2;
  }
  
  .status-button.pending {
    background-color: #fff9c4;
    color: #000;
  }
  
  .status-button.completed {
    background-color: #c8e6c9;
    color: #000;
  }
  
  .status-button.cancelled {
    background-color: #ffcdd2;
    color: #000;
  }
  
  @media (max-width: 768px) {
    .order-grid {
      grid-template-columns: 1fr;
    }
  }
  
  @media (max-width: 400px) {
    .admin-login h1,
    .admin-panel h1 {
      font-size: 1.2em;
    }
  
    .status-button {
      width: 35px;
      height: 35px;
    }
  
    .status-button svg {
      font-size: 18px;
    }
  }