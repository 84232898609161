/* Completed.css */
.completed-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
    border-radius: 6px;
  }
  
  .completed-content {
    background-color: #1d1d1d;
    border-radius: 10px;
    padding: 20px;
    text-align: center;
    max-width: 80%;
  }
  
  .completed-spinner {
    width: 60px;
    height: 60px;
    border: 5px solid rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    border-top-color: #0070CC;
    animation: spin 1s linear infinite;
    margin: 0 auto;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  .completed-checkmark {
    display: inline-block;
    width: 60px;
    height: 60px;
    margin: 0 auto 20px;
  }
  
  .checkmark-circle {
    width: 60px;
    height: 60px;
    position: relative;
    border-radius: 50%;
    background-color: #0070CC; /* PS5 blue */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .checkmark-img {
    height: 40px;
    width: 40px;
  }
  
  .completed-content h2 {
    color: #fff;
    margin: 0 0 20px 0;
    font-size: 1.5em;
  }
  
  .completed-content p {
    color: #ccc;
    margin: 0 0 20px 0;
    font-size: 1em;
  }
  
  .completed-close-button {
    background-color: #0070CC; /* PS5 blue */
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
  }
  
  .completed-close-button:hover {
    background-color: #0058a3;
  }