/* App.css */
html, body {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
  overflow: hidden;
  background-color: #111111;
  color: #ffffff;
}

#root {
  height: 100%;
}

.app-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.app-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  background-color: #000000;
  color: white;
}

.app-title {
  margin: 0;
  font-size: 24px;
  cursor: pointer;
}

.header-right {
  display: flex;
  align-items: center;
}

.social-icons {
  display: flex;
  gap: 15px;
}

.social-icons a {
  color: white;
  font-size: 20px;
  text-decoration: none;
  transition: color 0.3s ease;
}

.social-icons a:hover {
  color: #b3b3b3;
}

.main-content {
  flex: 1;
  padding: 40px;
  background-color: #111111;
  overflow-y: auto;
}

.coins-cart {
  background-color: #1a1a1a;
  border-radius: 6px;
  padding: 20px;
  display: flex;
  flex-direction: column;
}

.app-footer {
  background-color: #000000;
  padding: 10px 0;
  text-align: center;
}

.legal-links {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.legal-links a {
  color: #b3b3b3;
  text-decoration: none;
  font-size: 14px;
  transition: color 0.3s;
}

.legal-links a:hover, .legal-links a.selected {
  color: #ffffff;
}