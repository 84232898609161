/* src/Legal/Legal.css */
.legal-container {
    max-width: 800px;
    margin: 0 auto;
    color: #ffffff;
  }
  
  .legal-container h1 {
    font-size: 2em;
    margin: 0 0 20px 0;
  }
  
  .legal-container h2 {
    font-size: 1.5em;
    margin: 20px 0;
  }
  
  .legal-container p {
    margin-bottom: 20px;
    line-height: 1.6;
  }
  
  .legal-container ul {
    margin-bottom: 20px;
    padding-left: 20px;
  }
  
  .legal-container li {
    margin-bottom: 5px;
  }

  .contact-form {
    margin-top: 20px;
  }
  
  .form-group {
    margin-bottom: 20px;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 5px;
  }
  
  .form-group input,
  .form-group textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #444;
    background-color: #333;
    color: #fff;
    border-radius: 4px;
  }
  
  .form-group textarea {
    height: 150px;
    resize: vertical;
  }
  
  .submit-button {
    background-color: rgba(255, 255, 255, 0.3);
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
  }
  
  .submit-button:hover {
    background-color: rgba(255, 255, 255, 0.4);
  }
  
  @media (max-width: 768px) {
    .legal-container h1 {
      font-size: 1.8em;
    }
  
    .legal-container h2 {
      font-size: 1.3em;
    }
  }