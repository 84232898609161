/* Coins.css */
.coins-container {
  max-width: 1200px;
  margin: 0 auto;
  font-family: 'Roboto Mono', monospace;
  display: flex;
  gap: 40px;
}

.coins-form {
  flex: 1;
  order: 1;
}

.coins-transaction-type,
.coins-console-options,
.coins-amount-options {
  margin-bottom: 20px;
}

.coins-section-title {
  font-size: 1.1rem;
  color: #ffffff;
  margin-bottom: 10px;
}

.coins-transaction-buttons,
.coins-console-buttons,
.coins-amount-buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.coins-transaction-button,
.coins-console-button,
.coins-amount-button {
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 8px 12px;
  font-size: 0.9rem;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
}

.coins-transaction-button::before,
.coins-console-button::before,
.coins-amount-button::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  transition: opacity 0.3s ease;
}

.coins-transaction-button.selected::before,
.coins-console-button.selected::before,
.coins-amount-button.selected::before {
  opacity: 0;
}

.coins-transaction-button,
.coins-console-button {
  flex: 1 0 calc(50% - 4px);
}

.coins-amount-button {
  flex: 1 0 calc(33.333% - 5.333px);
}

.coins-transaction-button:hover,
.coins-console-button:hover,
.coins-amount-button:hover {
  opacity: 0.9;
}

.coins-transaction-button {
  background-color: #4a4a4a;
}

.ps5-blue {
  background-color: #0070cc;
}

.xbox-green {
  background-color: #107c10;
}

@media (max-width: 768px) {
  .coins-container {
    flex-direction: column;
  }

  .coins-form {
    order: 1;
  }
}

@media (max-width: 480px) {
  .coins-amount-button {
    flex: 1 0 calc(33.333% - 5.333px);
  }
}