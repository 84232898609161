/* Information.css */
.coins-info {
    flex: 1;
    order: 2;
  }

  .coins-info h2{
    margin: 0 0 20px 0;
  }
  
  .section-title {
    font-size: 1.5rem;
    color: #ffffff;
    margin-bottom: 20px;
    border-bottom: 2px solid #333;
    padding-bottom: 10px;
  }
  
  .info-text {
    margin-bottom: 20px;
    line-height: 1.6;
  }
  
  .disclaimer {
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 6px;
    padding: 20px;
    margin-top: 30px;
  }
  
  .disclaimer-title {
    font-size: 1.2rem;
    color: #ff6b6b;
    margin: 0 0 20px 0;
  }
  
  .disclaimer p {
    margin: 0;
  }