/* Checkout.css */
.checkout {
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 4px;
    padding: 20px;
    margin-top: 20px;
    position: relative;
  }

  .order-info-container {
    margin-bottom: 30px;
  }
  
  .checkout-section-title {
    font-size: 1.1rem;
    color: #ffffff;
  }
  
  .checkout-cart-cost {
    font-size: 2rem;
    font-weight: bold;
    color: #ffffff;
    margin-top: 10px;
  }
  
  .checkout-cart-details {
    margin-bottom: 30px;
  }
  
  .checkout-cart-details p {
    margin: 10px 0 0 0;
  }

  .payment-method-container {
    margin-bottom: 30px;
  }
  
  .payment-options {
    display: flex;
    gap: 10px;
  }
  
  .payment-option.paypal {
    background-color: #FCBB32;
  }
  
  .payment-option.venmo {
    background-color: #d3d3d3;
  }
  
  .payment-option {
    min-height: 40px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0 10px;
    border-radius: 4px;
    cursor: pointer;
    flex: 1;
    margin-top: 10px;
  }
  
  .payment-option input[type="radio"] {
    margin: 0;
    vertical-align: middle;
  }
  
  .payment-logo {
    height: 20px;
    width: auto;
    max-width: 80px;
    margin-left: auto;
    margin-right: auto;
    object-fit: contain;
  }
  
  .payment-option-content {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  
  .payment-input {
    width: 100%;
    height: 45px;
    padding: 0 15px;
    border: none;
    border-radius: 4px;
    font-size: 1rem;
    box-sizing: border-box;
    background-color: rgba(255, 255, 255, 0.1);
    color: #ffffff;
    margin-top: 10px;
  }
  
  .payment-input::placeholder {
    color: rgba(255, 255, 255, 0.5);
  }
  
  .contact-method-container {
    margin-bottom: 30px;
  }
  
  .contact-input-group {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 10px;
  }
  
  .contact-method-options {
    display: flex;
    gap: 10px;
  }
  
  .contact-method-icon {
    width: 24px;
    height: 24px;
    cursor: pointer;
    opacity: 0.3;
    transition: opacity 0.3s ease;
  }
  
  .contact-method-icon.selected {
    opacity: 1;
  }
  
  .contact-input {
    width: 100%;
    flex: 1;
    height: 45px;
    padding: 0 15px;
    border: none;
    border-radius: 4px;
    font-size: 1rem;
    background-color: rgba(255, 255, 255, 0.1);
    color: #ffffff;
  }
  
  .contact-input::placeholder {
    color: rgba(255, 255, 255, 0.5);
  }
  
  .checkout-buy-button {
    width: 100%;
    height: 45px;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 0 15px;
    font-size: 1rem;
    cursor: pointer;
    transition: opacity 0.3s ease;
    margin-bottom: 30px;
    background-color: #0070CC; /* PS5 blue */
  }
  
  .checkout-buy-button:hover:not(:disabled) {
    opacity: 0.8;
  }
  
  .checkout-buy-button:disabled,
  .checkout-buy-button.disabled {
    background-color: #888;
    cursor: default;
  }
  
  .checkout-spinner {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 4px solid #333;
    border-top: 4px solid #fff;
    animation: spin 1s linear infinite;
    margin: 0 auto;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  .checkout-features {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .checkout-feature-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    flex: 1;
    padding: 0 10px;
    max-width: 33%;
  }
  
  .checkout-feature-item img {
    width: 40px;
    height: 40px;
    margin-bottom: 10px;
  }
  
  .checkout-feature-item span {
    font-size: 0.8rem;
    color: #ffffff;
    white-space: nowrap;
  }
  
  @media (max-width: 768px) {
    .checkout-features {
      justify-content: space-around;
    }
  
    .checkout-feature-item {
      max-width: none;
    }
  
    .payment-options {
      flex-direction: column;
      gap: 0px;
    }
  }
  
  @media (max-width: 480px) {
    .checkout-feature-item span {
      white-space: normal;
    }
  }